/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with jQuery, even when in .noConflict() mode.
 * ======================================================================== */

(function(jQuery) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        /* SVG */
        jQuery('img.svg').each(function() {
          var jQueryimg = jQuery(this);
          var imgID = jQueryimg.attr('id');
          var imgClass = jQueryimg.attr('class');
          var imgURL = jQueryimg.attr('src');

          jQuery.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var jQuerysvg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
              jQuerysvg = jQuerysvg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
              jQuerysvg = jQuerysvg.attr('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            jQuerysvg = jQuerysvg.removeAttr('xmlns:a');

            // Check if the viewport is set, else we gonna set it if we can.
            if (!jQuerysvg.attr('viewBox') && jQuerysvg.attr('height') && jQuerysvg.attr('width')) {
              jQuerysvg.attr('viewBox', '0 0 ' + jQuerysvg.attr('height') + ' ' + jQuerysvg.attr('width'));
            }

            // Replace image with new SVG
            jQueryimg.replaceWith(jQuerysvg);

          }, 'xml');

        });

        /* Side menu */
        // Open navbarSide when button is clicked
        jQuery('#navbarSideButton').on('click', function() {
          jQuery('#navbarSide').toggleClass('reveal');
          jQuery('#nav-icon').toggleClass('open');
          jQuery('.overlay').show();
        });

        /*Video*/
        if (document.getElementById("video-container")) {
          // Video
          var video = document.getElementById("video");

          // Buttons
          var playButton = document.getElementById("play-pause");

          // Event listener for the play/pause button
          playButton.addEventListener("click", function() {
            if (video.paused === true) {
              // Play the video
              video.play();
              jQuery('.play').css('display', 'none');
              jQuery('.overlay').css('display', 'none');
              //jQuery('.pause').css('display','block');
            } else {
              //Pause the video
              video.pause();
              jQuery('.play').css('display', 'block');
              jQuery('.pause').css('display', 'none');
              jQuery('.overlay').css('display', 'block');
            }
          });
          jQuery(video).mouseleave(function() {
            if (video.paused === false) {
              //jQuery('.pause').toggleClass('display-none');
              jQuery('.pause').css('display', 'none');
            }
          });
          jQuery(video).mouseenter(function() {
            if (video.paused === false) {
              //jQuery('.pause').removeClass('display-none');
              jQuery('.pause').css('display', 'block');
              jQuery('.pause').on('hover', function() {
                jQuery('.pause').css('display', 'block');
              });
            }
          });
          var pause = document.getElementById("pause");
          pause.addEventListener("click", function() {
            jQuery('.pause').css('display', 'none');
            jQuery('.pause').on('hover', function() {
              jQuery('.pause').css('display', 'none');
            });
            if (video.paused === false) {
              jQuery('.overlay').css('display', 'block');
            }
          });
        }

        /* Email and Zip Validation */
        jQuery.validator.addMethod("validate_email", function(value, element) {

          if (/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+jQuery/.test(value)) {
            return true;
          } else {
            return false;
          }
        }, "Please enter a valid Email.");

        jQuery.validator.addMethod('zipcode', function(value, element) {

          return this.optional(element) || (/^\d{5}(?:[-\s]\d{4})?jQuery/.test(value.trim()) && value !== jQuery(element).attr('placeholder'));

        }, 'Invalid zip code');

        jQuery('.share-items').on('click', function() {
          //jQuery(".icons").slideToggle("slow", {direction: "right"}, 100);
          //console.log(jQuery(this).children('.share'));
          jQuery(this).children('.share').toggleClass('hide-share');
          jQuery(this).children('.close').toggleClass('show-close');
          jQuery(this).children('.icons').toggleClass('show');
        });

        if (jQuery('#items').length) {
          var items = jQuery('#items').offset().top - 100;
          jQuery(document).scroll(function() {
            if (jQuery(this).scrollTop() > items) {
              jQuery('.fixed-top').addClass('nav-bg');
            } else {
              jQuery('.fixed-top').removeClass('nav-bg');
            }
          });
        }

        enquire.register("screen and (min-width:992px)", {
          match: function() {
            // Code for Desktop
            jQuery('.scroll').on('click', function(e) {
              e.preventDefault();
              var offset = 100;
              var target = this.hash;
              if (jQuery(this).data('offset') !== undefined) {
                offset = jQuery(this).data('offset');
              }
              jQuery('html, body').stop().animate({
                'scrollTop': jQuery(target).offset().top - offset
              }, 500, 'swing', function() {
                // window.location.hash = target;
              });
            });

            if (jQuery('.page-template-template-home').length) {
              var highlightD = jQuery('.page-template-template-home').offset().top;
              jQuery(document).scroll(function() {
                if (jQuery(this).scrollTop() > highlightD) {
                  jQuery('.fixed-top').addClass('nav-bg');
                } else {
                  jQuery('.fixed-top').removeClass('nav-bg');
                }
              });
            }
            jQuery(window).on('load scroll', function() {
              var scrolled = jQuery(this).scrollTop();

              jQuery('#hero-vid').css('transform', 'translate3d(0, ' + -(scrolled * 0.25) + 'px, 0)'); // parallax (25% scroll rate)
            });

            // video controls
            jQuery('#state').on('click', function() {
              var video = jQuery('#hero-vid').get(0);
              var icons = jQuery('#state > span');
              //jQuery('#overlay').toggleClass('fade');
              if (video.paused) {
                video.play();
                icons.removeClass('fa-play').addClass('fa-pause');
              } else {
                video.pause();
                icons.removeClass('fa-pause').addClass('fa-play');
              }
            });

            /* Footer Contact Form - desktop */
            jQuery("#get-involved-form-desktop").validate({
              rules: {
                email_field_d: {
                  required: true,
                  //email       : true,
                  validate_email: true
                },
                zip_field_d: {
                  required: true,
                  minlength: 5,
                  zipcode: true
                }
              },
              messages: {
                zip_field_d: {
                  required: "Invalid Zip",
                  minlength: "5 character min",
                },
                email_field_d: {
                  required: "Enter an Email",
                  email: "Not Valid"
                },
              },
              errorPlacement: function(error, element) {
                //element.val(error[0].outerText);
                element.attr("placeholder", error[0].outerText);
                //element.attr('placeholder', error.text());
              }, //Puts errors as placeholders
              submitHandler: function(form) {
                var url = "http://cred18.wpengine.com/process/process_form_v2.php";

                var email = document.getElementById("email-field-d").value,
                  zip = document.getElementById("zip-field-d").value,
                  via = document.getElementById("via-d").value,
                  page_name = document.getElementById("page_name-d").value,
                  homepage_category = document.getElementById("homepage-category-d").value;

                var data_pass = { "email": email, "zip": zip, "via": via, "page_name": page_name, "explore_category": homepage_category };
                jQuery.ajax({
                  type: "POST",
                  url: url,
                  data: data_pass,
                  cache: false,
                  dataType: 'json',
                  success: function(data) {
                    jQuery("#email-field-d").hide();
                    jQuery("#zip-field-d").hide();
                    jQuery(".submit-desktop").hide();
                    if (data.err === false) {
                      jQuery("#form-message-d").show().text('Thank you for registering.');
                    } else {
                      jQuery("#form-message-d").show().text("Email already registered.").delay(10000);
                    }
                  }
                });
                //alert ("form submitted");
              }
            }); //add rules
            jQuery(this).find("input[type=text]").each(function() {
              if (jQuery(this).attr("placeholder") === jQuery(this).val()) {
                jQuery(this).val("");
              }
            }); //validate everything

            jQuery('.form-control').on('click focusin', function() {
              this.value = '';
            }); //cleans fields

            jQuery('#email-field-d').on('click', function() {
              jQuery('#email-field-d .error').removeClass('error');
              jQuery(this).addClass('success');
            });
          },
          unmatch: function() {
            // Code to un-set or reset when leaving this query.
          }
        });

        enquire.register("screen and (max-width:991px)", {
          match: function() {
            // Code for mobile
            jQuery('.scroll').on('click', function(e) {
              e.preventDefault();
              var offset = 100;
              var target = this.hash;
              if (jQuery(this).data('offset') !== undefined) {
                offset = jQuery(this).data('offset');
              }
              jQuery('html, body').stop().animate({
                'scrollTop': jQuery(target).offset().top - offset
              }, 500, 'swing', function() {
                // window.location.hash = target;
              });
            });

            if (jQuery('.page-template-template-home').length) {
              var highlightM = jQuery('.page-template-template-home').offset().top;

              jQuery(document).scroll(function() {
                if (jQuery(this).scrollTop() > highlightM) {
                  jQuery('.fixed-top').addClass('nav-bg');
                } else {
                  jQuery('.fixed-top').removeClass('nav-bg');
                }
              });
            }

            if (jQuery('#splash-inner').length) {
              var splash = jQuery('#splash-inner').offset().top - 100;

              jQuery(document).scroll(function() {
                if (jQuery(this).scrollTop() > splash) {
                  jQuery('.fixed-top').addClass('nav-bg');
                } else {
                  jQuery('.fixed-top').removeClass('nav-bg');
                }
              });
            }

            /* Footer Contact Form - mobile */
            jQuery("#get-involved-form-mobile").validate({
              rules: {
                email_field_m: {
                  required: true,
                  //email       : true,
                  validate_email: true
                },
                zip_field_m: {
                  required: true,
                  minlength: 5,
                  zipcode: true
                }
              },
              messages: {
                zip_field_m: {
                  required: "Invalid Zip",
                  minlength: "5 character min",
                },
                email_field_m: {
                  required: "Enter an Email",
                  email: "Not Valid"
                },
              },
              errorPlacement: function(error, element) {
                element.attr("placeholder", error[0].outerText);
              }, //Puts errors as placeholders
              submitHandler: function(form) {
                var url = "http://cred18.wpengine.com/process/process_form_v2.php";

                var email = document.getElementById("email-field-m").value,
                  zip = document.getElementById("zip-field-m").value,
                  via = document.getElementById("via-m").value,
                  page_name = document.getElementById("page_name-m").value,
                  homepage_category = document.getElementById("homepage-category-m").value;

                var data_pass = { "email": email, "zip": zip, "via": via, "page_name": page_name, "explore_category": homepage_category };
                jQuery.ajax({
                  type: "POST",
                  url: url,
                  data: data_pass,
                  cache: false,
                  dataType: 'json',
                  success: function(data) {
                    jQuery("#email-field-m").hide();
                    jQuery("#zip-field-m").hide();
                    jQuery(".submit-mobile").hide();
                    if (data.err === false) {
                      jQuery("#form-message-m").show().text('Thank you for registering.');
                    } else {
                      jQuery("#form-message-m").show().text("Email already registered.").delay(10000);
                    }
                  }
                });
                //alert ("form submitted");
              }
            }); //add rules
            jQuery(this).find("input[type=text]").each(function() {
              if (jQuery(this).attr("placeholder") === jQuery(this).val()) {
                jQuery(this).val("");
              }
            }); //validate everything

            jQuery('.form-control').on('click focusin', function() {
              this.value = '';
            }); //cleans fields

            jQuery('#email-field-m').on('click', function() {
              jQuery('#email-field-m .error').removeClass('error');
              jQuery(this).addClass('success');
            });
          },
          unmatch: function() {
            // Code to un-set or reset when leaving this query.
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      jQuery.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  jQuery(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


//btn-down
jQuery(document).ready(function() {
  jQuery("#btn-down").on("click", "a", function(event) {
    event.preventDefault();
    var id = jQuery(this).attr('href'),
      top = jQuery(id).offset().top;
    jQuery('body,html').animate({ scrollTop: top }, 1500);
  });
});

//block-filter-search
jQuery(document).ready(function() {
  jQuery('.block-filter-search-mobile').on('click', function() {
    if (jQuery(this).parent().find('.block-filter-search-in').css('display') == 'block') {
      jQuery(this).parent().find('.block-filter-search-in').css({ 'display': 'none' });
      jQuery(this).find('.fas').removeClass('fa-chevron-circle-down').addClass('fa-chevron-circle-right');
    } else {
      jQuery(this).parent().find('.block-filter-search-in').css({ 'display': 'block' });
      jQuery(this).find('.fas').removeClass('fa-chevron-circle-right').addClass('fa-chevron-circle-down');
    }
  });
});

// scrole animation
var jQuerywindow = jQuery(window);
var jQueryelem = jQuery(".visibility-animation")

function isScrolledIntoView(jQueryelem, jQuerywindow) {
  var docViewTop = jQuerywindow.scrollTop();
  var docViewBottom = docViewTop + jQuerywindow.height();

  var elemTop = jQueryelem.offset().top;
  var elemBottom = elemTop + jQueryelem.height();

  return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

jQuery(document).on("scroll", function() {
  if (isScrolledIntoView(jQueryelem, jQuerywindow)) {
    jQueryelem.addClass("animate")
    console.log("now you see me");
  }
});


// mobile menu init
function initMobileNav() {
  jQuery('body').mobileNav({
    menuActiveClass: 'nav-active',
    menuOpener: '.nav-opener'
  });
}

function initLinkAnimation() {
  jQuery('.nav-opener').click(function() {
    jQuery('#nav li').each(function(e) {
      var context = jQuery(this);
      if (jQuery('body').hasClass('nav-active')) {
        context.removeClass('show-link');
      }
      setTimeout(function() { context.addClass('show-link') }, 100 * e);
    })
  })
}

jQuery(function() {
  initMobileNav();
  initLinkAnimation();
});

/*
 * Simple Mobile Navigation
 */
(function(jQuery) {
  function MobileNav(options) {
    this.options = jQuery.extend({
      container: null,
      hideOnClickOutside: false,
      menuActiveClass: 'nav-active',
      menuOpener: '.nav-opener',
      menuDrop: '.nav-drop',
      toggleEvent: 'click',
      outsideClickEvent: 'click touchstart pointerdown MSPointerDown'
    }, options);
    this.initStructure();
    this.attachEvents();
  }
  MobileNav.prototype = {
    initStructure: function() {
      this.page = jQuery('html');
      this.container = jQuery(this.options.container);
      this.opener = this.container.find(this.options.menuOpener);
      this.drop = this.container.find(this.options.menuDrop);
    },
    attachEvents: function() {
      var self = this;

      if (activateResizeHandler) {
        activateResizeHandler();
        activateResizeHandler = null;
      }

      this.outsideClickHandler = function(e) {
        if (self.isOpened()) {
          var target = jQuery(e.target);
          if (!target.closest(self.opener).length && !target.closest(self.drop).length) {
            self.hide();
          }
        }
      };

      this.openerClickHandler = function(e) {
        e.preventDefault();
        self.toggle();
      };

      this.opener.on(this.options.toggleEvent, this.openerClickHandler);
    },
    isOpened: function() {
      return this.container.hasClass(this.options.menuActiveClass);
    },
    show: function() {
      this.container.addClass(this.options.menuActiveClass);
      if (this.options.hideOnClickOutside) {
        this.page.on(this.options.outsideClickEvent, this.outsideClickHandler);
      }
    },
    hide: function() {
      this.container.removeClass(this.options.menuActiveClass);
      if (this.options.hideOnClickOutside) {
        this.page.off(this.options.outsideClickEvent, this.outsideClickHandler);
      }
    },
    toggle: function() {
      if (this.isOpened()) {
        this.hide();
      } else {
        this.show();
      }
    },
    destroy: function() {
      this.container.removeClass(this.options.menuActiveClass);
      this.opener.off(this.options.toggleEvent, this.clickHandler);
      this.page.off(this.options.outsideClickEvent, this.outsideClickHandler);
    }
  };

  var activateResizeHandler = function() {
    var win = jQuery(window),
      doc = jQuery('html'),
      resizeClass = 'resize-active',
      flag, timer;
    var removeClassHandler = function() {
      flag = false;
      doc.removeClass(resizeClass);
    };
    var resizeHandler = function() {
      if (!flag) {
        flag = true;
        doc.addClass(resizeClass);
      }
      clearTimeout(timer);
      timer = setTimeout(removeClassHandler, 500);
    };
    win.on('resize orientationchange', resizeHandler);
  };

  jQuery.fn.mobileNav = function(opt) {
    var args = Array.prototype.slice.call(arguments);
    var method = args[0];

    return this.each(function() {
      var jQuerycontainer = jQuery(this);
      var instance = jQuerycontainer.data('MobileNav');

      if (typeof opt === 'object' || typeof opt === 'undefined') {
        jQuerycontainer.data('MobileNav', new MobileNav(jQuery.extend({
          container: this
        }, opt)));
      } else if (typeof method === 'string' && instance) {
        if (typeof instance[method] === 'function') {
          args.shift();
          instance[method].apply(instance, args);
        }
      }
    });
  };
}(jQuery));
